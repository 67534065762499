@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Merriweather:wght@300;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-light-gray text-navy font-sans;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-serif;
}

.btn {
  @apply bg-teal text-white px-6 py-3 rounded-full text-lg hover:bg-coral transition duration-300;
}
